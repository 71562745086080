.svg-common() {
	background: url("svg/sprite.view.svg") no-repeat;
}

.svg-logo_color {
	.svg-common();
	background-position: 0 0;
}

.svg-logo_color-dims {
	width: 468.8px;
	height: 187.7px;
}

.svg-logo_white {
	.svg-common();
	background-position: 0 99.68135953266064%;
}

.svg-logo_white-dims {
	width: 468.8px;
	height: 187.7px;
}

