.parallax-wrapper{
    .border-bottom-radius(55% 40%);
    margin-bottom: 40px;
    width: 100%;
    .box-sizing(content-box);
    padding: 0 100px 25px;
    margin-left: -100px;

    background : @almostblack url('@{public}images/header-bg.jpg') no-repeat top center;

    @media (min-width: 1500px){
        background : @almostblack url('@{public}images/header-bg@3000.jpg') no-repeat top center;
       .background-size(100% auto);
    }

    @media (max-width: @screen-lg){
        background : @almostblack url('@{public}images/header-bg@1280.jpg') no-repeat top center;
        .background-size(100% auto);
    }
    @media (max-width: @screen-md-max){
        .background-size(cover);
    }
    @media (max-width: @screen-sm-max){
        background : @almostblack url('@{public}images/header-bg@1024.jpg') no-repeat top center;
    }
    @media (max-width: @screen-xs-max){
        background : @almostblack url('@{public}images/header-bg@768.jpg') no-repeat top center;
        .background-size(auto 100%);
    }
}
.parallax-outer{
    overflow: hidden;
    margin-bottom: 16px;
}