.contacts{
    margin-top: 40px;
    td{
        font-size: (@font-size-base + 2);
        padding-right: 8px;
    }
    td:first-child{
        font-size: @font-size-base;
    }
}
.footer{
    background-color: @gray-dark;
    .navbar-brand{
        padding: 18px 0;
    }
}
.copyright{
    padding: 18px 0;
    background-color: darken(@gray-dark,3%);
}