//
// general style for layouts
//
html {
    color       : @white;

    body {

        // nice fonts Safari
        text-rendering         : optimizeLegibility;
        -webkit-font-smoothing : antialiased;
    }
}

// box-sizing to all
*, *:before, *:after {
    .box-sizing( border-box );
}

// container & wrapper
.container,
.container-fluid{
    width     : 100%;
    max-width: @max-width + @grid-gutter-width;
    margin: 0 auto;
}
.wrapper {
    margin       : 0 auto;
    position     : relative;
}

.content{
    padding-bottom: 60px;
    @media (max-width: @screen-sm-max) {
        padding-bottom: 40px;
    }
}