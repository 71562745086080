.title{
    padding-top: 98px;
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
    @media (max-width: @screen-lg){
        padding-top: 0;
    }
    @media (max-width: @screen-sm-max){

    }
    @media (max-width: @screen-xs-max){
        margin-top:-50px;
        .title-small{
            font-size: 20px;
            margin-bottom: 25px;
            padding-bottom: 25px;
        }

    }
}
.title-header{
    text-transform: uppercase;
    font-weight: normal;
}
.title-small{
    text-align: center;
    font-family: @font-family-serif;
    font-size: 25px;
    font-weight: 300;
    margin-top: 17px;
    padding-bottom: 53px;
    margin-bottom: 50px;
    position: relative;
    &:after{
        content: "";
        width: 124px;
        height: 2px;
        display: block;
        position: absolute;
        background-color: fade(@white,60%);
        left: 50%;
        .transform(translateX(-50%));
        bottom: 0;

    }
}