.scroll-down{
    .icon;
    .icon-arrow-down;
    font-size: 34px;
    width: 30px;
    display: block;
    margin: 44px auto 0;
    text-decoration: none;
    color: @white;
    .opacity(0.6);
    cursor: pointer;
    @media (max-width: @screen-xs-max) {
        margin-top: 25px;
    }
    &:hover, &:hover:focus{
        .opacity(1);
        color: @white;
        .animation(bounce 2s infinite);
    }
    &:focus{
        color: @white;
        .opacity(0.6);
    }
}

.keyframes(bounce;
{
    0%, 20%, 80%, 100% {
        .transform(translateY(0));
    }
    50% {
        .transform(translateY(15px));
    }
}
);