.btn-to-top{
    position: fixed;
    bottom: 98px;
    right: 10px;
    text-align: center;
    cursor: pointer;
    .opacity(.8);
    display: none;
    z-index: @zindex-modal - 1;
    &.bottom{
        position: absolute;
    }
    &:hover{
        .opacity(1);
    }
    @media (min-width: @screen-sm-min){
        right: 30px;
    }
    @media (min-width: @screen-md-min){
        right: 170px;
    }
    @media (max-width: @screen-xs-max) {
        bottom: 80px;
    }
    .icon{
        color: @white;
        font-size: 20px;
    }
    span{
        color: @white;
        display: block;
        font-size: @font-size-small;
        font-family: @font-family-sans-serif;
        font-weight: 300;
        margin-top: -5px;
    }
}