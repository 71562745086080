.bootstrap-select{
    &.btn-group.open{
        .dropdown-toggle{
            .box-shadow(none);
        }
    }
    .btn.btn-default{
        background-color: @input-bg;
        border: 1px solid @input-border;
        color: @white;
        font-weight: normal;
        text-transform: none;
        padding: 12px 40px 12px 20px;
        height: 55px;
        font-size: @font-size-h4;
        @media (max-width: @screen-xs-max) {
            height: 40px;
            font-size: 14px;
            padding: 6px 10px;
        }
        &:hover,
        &:active:hover,
        &:focus:hover,
        &.focus  {
            background-color: @input-bg;
            border: 1px solid @input-border;
            color: @white;
            outline: 0!important;
        }
        &.dropdown-toggle {
            &:focus, &:focus:active, &:active {
                outline: 0!important;
            }
        }
        .caret{
            border: 0;
            width: auto;
            height: auto;
            margin-top: -8px;
            .icon;
            .icon-dropdown-arrow;
            font-size: 14px;
            @media (max-width: @screen-xs-max) {
                font-size: 8px;
                margin-top: -4px;
            }
        }
    }
    .filter-option{
        line-height: @line-height-base;
    }
    .dropdown-menu{
        margin-top: -1px;
        border: 0;
        padding: 0;

        border-top: 1px solid lighten(@input-bg,5%);
        .box-shadow(none);
        .border-radius(0);
        background-color: @input-bg;
        >li{
            >a {
                font-style: normal;
                color: @white;
                padding: 5px 11px;
                @media (min-width: @screen-sm-min) {
                    font-size: 18px;
                    padding: 8px 20px;
                }
                &:focus{
                    outline: 0;
                }
            }
            &.selected>a,
            &:hover>a{
             background-color: fade(@white,20%);
            }
        }
        li + li {
            border-top: 1px solid lighten(@input-bg,5%);
        }
    }
    &.dropup{
        >.dropdown-menu{
           margin-top: 0;
            margin-bottom: -1px;
            border-top: 0;
            border-bottom: 1px solid lighten(@input-bg,5%);
        }
        .btn.dropdown-toggle{

        }
    }
}