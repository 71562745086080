//
// Extra responsive less
//
@media (max-width: @screen-xs-max) {
    h3, .h3{
        font-size: 18px;
    }
    .control-label, .form-control{
        font-size: 14px;
    }
    .form-group{
        margin-top: 20px;
    }
    .form-control{
        padding: 6px 10px;
        height: 40px;
    }
    .btn{
        font-size: 14px;
        padding: 6px 40px;
    }
}