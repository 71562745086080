@font-face {
    font-family: 'plum';
    src: url('@{public}fonts/plum/plum.eot?65698859');
    src: url('@{public}fonts/plum/plum.eot?65698859#iefix') format('embedded-opentype'),
    url('@{public}fonts/plum/plum.woff2?65698859') format('woff2'),
    url('@{public}fonts/plum/plum.woff?65698859') format('woff'),
    url('@{public}fonts/plum/plum.ttf?65698859') format('truetype'),
    url('@{public}fonts/plum/plum.svg?65698859#plum') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'plum';
    src: url('../font/plum.svg?65698859#plum') format('svg');
  }
}
*/

.icon{
    &:before {
        font-family: "plum";
        font-style: normal;
        font-weight: normal;
        speak: none;

        display: inline-block;
        text-decoration: inherit;

        text-align: center;
        /* opacity: .8; */

        /* For safety - reset parent less, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;


        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
}

.icon-arrow-down{&:before { content: '\e800'; } }
.icon-dropdown-arrow{&:before { content: '\e801'; } }
.icon-arrow-up{&:before { content: '\e802'; } }